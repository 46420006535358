.large {
    font-size:80px;
}

a.btn.orange {
    background-color: #C8602D;
    color: #fff;
    text-transform: uppercase;
    margin-right: 10px;
    margin-top: 10px;
}


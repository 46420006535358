.signin-icon {
    max-width: 180px;
    padding-bottom: 15px;
    margin-top: 25px;
}

.paper {
    margin-top: 28px;
    /*display: flex;*/
    flex-direction: column;
    align-items: center;
    padding: 3rem;
}

.sideBarMenuContainer {
    /*position: fixed;*/
    /*height: 100vh;*/
    /*height: 100%;*/
    /*background-color: #494C4E;*/
}

.menu-item-icon {
    padding-right: 18px !important;
    /*font-size: 1.5rem!important;*/
    font-size: 1.6em !important;
    width: 20px !important;
}

.navbar-custom {
    background-color: #494C4E;
}

ul.nav.navbar-nav > li > a {
    color: #fff;
    text-transform: uppercase;
}

ul.nav.navbar-nav > li {
    border-bottom: 1px solid #DDDDDC;
}


.navbar li .glyphicon {
    margin-right: 20px;
}

/* Highlighting rules for nav menu items */
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-image: none;
    background-color: #4189C7;
    color: #fff;
}

.FensaBody{
    background-color: #F2F2F2;
}

.headerClass {
    background-color: #fff;
    height: 30px;
}

.Banner {
    background-color: #fff;
    height: 140px;
}

@media (min-width: 768px) {
    /* On large screens, convert the nav menu to a vertical sidebar */
    .navbar {
        height: 100%;
        width: calc(16.67%);
    }
    
    /*.navbar-fixed-top {
        top: 130px;
    }*/

    .navbar {
        border-radius: 0;
        border-width: 0;
        height: 100%;
        width: 100%;
    }
    .navbar-header {
        float: none;
    }
    .navbar .navbar-collapse {
        border-top: 1px solid #444;
        padding: 0;
    }
    .navbar .container-fluid {
        padding: 0;
        margin: 0;
    }
    .navbar .container-fluid .navbar-brand {
        margin: 0;
    }
    .navbar ul {
        float: none;
    }
    .navbar li {
        float: none;
        font-size: 15px;
        margin: 6px;
    }
    .navbar li a {
        padding: 10px 16px;
        border-radius: 4px;
    }
    .navbar a {
        /* If a menu item's text is too long, truncate it */
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

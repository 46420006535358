@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.invalid-input-margin
{
    margin-top: -2.2rem;
}


.cookieConsent {
    z-index: 10000 !important;
}

.notice-message {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}

.fa-7x {
    font-size: 6em;
}

/*.fa-check{
    color:green;
}*/

.fa-times {
    color: red;
}

.card-wrapper {
    width: 33%;
}

.card-item-wrapper {
    padding: 10px 15px;
    line-height: 1.31 !important;
    font-size: 0.85rem;
}

    /*Truncate text to 220px*/
    .card-item-wrapper .card-item-text {
        width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.card-item-highlight {
    /*border: 1px solid rgba(10, 50, 150, 0.5);*/
    box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);
}

.product-type .product-small {
    width: 35px !important;
    float: left;
    margin-right: 10px;
    /*max-height: 35px !important;*/
}

.small-slider {
    width: 80px !important;
}

.invalid-input {
    position: absolute;
    top: 100%;
    z-index: 5;
    max-width: 100%;
    padding-left: .5rem;
    padding-top: .1rem;
    padding-right: .5rem;
    padding-bottom: .5rem;
    margin-top: .8rem;
    font-size: .775rem;
    line-height: 1;
    color: #ff0000;
    border-radius: .2rem;
}

/*.main-branch {
    /*border: 1px solid rgba(10, 50, 150, 0.5);* /
    box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);
}

.main-contact {
    /*border: 1px solid rgba(10, 50, 150, 0.5);* /
    box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);
}*/

.headerClass {
    height: 20px;
}

    .headerClass img {
        width: 70px;
        margin-top: 5px;
    }

.row {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
}

.FensaBody {
    padding: 20px 50px;
    min-height: 100vh;
}

.Banner {
    height: 120px;
}

    .Banner img {
        width: 160px;
    }

.img-banner {
    width: 80px;
}

.small2 {
    padding-left: 0;
    padding-right: 0;
    /*min-height: 100vh;*/
    min-height: 100%;
}

.workarea-bg {
    background-color: #444444 !important;
}

.workarea {
    background-color: #F2F2F2 !important;
}

.sign-in-container {
    /*padding-top: 120px !important;
    padding-bottom: 40px !important;*/
}

    .sign-in-container h2 {
        text-align: center;
    }

.avatar {
    max-width: 20px !important;
}

.form-check-input {
    margin-left: 0;
}

.Layout-drawerPaperClose-14 {
    width: 60px !important;
}

/*circle icons*/
.FixedHeight {
    background-color: #e6e6e6;
    border-radius: 50%;
    padding: 40px 0;
    display: inline-block;
    width: 180px;
    height: 180px;
}

    .FixedHeight:hover {
        /*background-color: rgba(106, 33, 79, 0.20);*/
        background-color: #fff;
    }




.MenuFixedHeight {
    /*height: 300px;*/
}

.Centre {
    text-align: center;
    color: #4d4d4d;
    margin-top: 10px;
}

    .Centre h2 {
        font-size: 19px;
        padding-top: 10px;
    }

.fa-7x {
    color: #6A214F;
}

.text-link {
    cursor: pointer;
}

/*h1*/
.FensaBody h1 {
    color: #6A214F;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
}

.login {
    background-color: #e6e6e6;
}

/*Dropdown user login*/

button.btn.btn-user-button {
    background-color: #e6e6e6;
    color: #6A214F;
    border-radius: 8px;
    border: 1px solid #fff;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 80px;
}

span.glyphicon.glyphicon-user {
    color: #6A214F;
}

ul.super-colors.dropdown-menu {
    background-color: #e6e6e6;
    color: #6A214F;
}

.dropdown-menu .divider {
    background-color: #fff;
}

.action-button {
    color: #6A214F;
    margin-top: 15px;
    border: none;
    font-size: 30px;
}

/*NOTIFICATION*/
.notification {
    color: white;
    text-decoration: none;
    position: relative;
    border-radius: 2px;
    margin-right: 30px;
}

    .notification .badge {
        position: absolute;
        top: 10px;
        right: -10px;
        padding: 7px 10px;
        border-radius: 50%;
        background: red;
        color: white;
    }

/*.text-danger {
    display: none;
}*/

.demoForm .row {
    margin-bottom: 10px;
}



.product-type .active {
    background-color: #fff;
    border: 2px solid #6A214F;
    color: #6A214F;
    font-weight: bold;
}

.product-type img {
    width: 50%;
    margin-bottom: 5px;
}

.btn-group p {
    text-align: center;
    margin-top: 10px;
}


.product-column {
    /*background-color: #fff;*/
    /*color: #6A214F;*/
    /*border-left: 1px solid rgba(95, 100, 109, 0.2);*/
    height: auto;
    overflow-y: auto;
    /*box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);*/
    margin-bottom: 25px !important;
    padding-top: 8px !important;
    /*padding-left: 35px !important;*/
}

.btn-normal-size {
    font-size: 0.99rem !important;
    font-weight: 400 !important;
}

    .btn-normal-size i {
        font-size: 1.45rem !important;
    }

.fa-icon-normal-size {
    font-size: 1.45rem !important;
}

.mdb-btn-resize {
    font-size: 0.85rem !important;
}

.look-up {
    /*margin-top: 5px;*/
}


.form-group-container {
    border-color: #444444;
    border-radius: 5px;
    /*padding: 30px;*/
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    box-shadow: 0 1px 5px 0.3rem rgba(0, 0, 0, 0.2);
    margin-left: auto !important;
    margin-right: auto !important;
    /*width: 750px;*/
    width: 100% !important;
}

    .form-group-container .form-group-title {
        font-family: inherit;
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        margin-top: 20px;
        margin-bottom: 30px;
    }

.form-group-fluid-container {
    background-color: #fff;
    border-color: #444444;
    border-radius: 5px;
    /*padding: 30px;*/
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    box-shadow: 0 1px 5px 0.3rem rgba(0, 0, 0, 0.2);
    margin-left: auto !important;
    margin-right: auto !important;
    width: auto;
}

    .form-group-fluid-container .form-group-title {
        font-family: inherit;
        /*font-size: 2.5rem;*/
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        margin-top: 20px;
        margin-bottom: 30px;
    }

@media(max-width: 577px) {
    .form-group-container {
        border-color: #444444;
        border-radius: 5px;
        padding: 30px;
        margin-bottom: 20px;
        box-shadow: 0 1px 5px 0.3rem rgba(0, 0, 0, 0.2);
        width: 100%;
        max-width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
    }

        .form-group-container .form-group-title {
            font-family: inherit;
            /*font-size: 2.0rem;*/
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
            margin-top: 10px;
            margin-bottom: 20px;
        }
}



/*tables*/

.ReactTable {
    border-radius: 10px;
}

.rt-resizable-header-content {
    color: #6A214F;
    font-weight: 600;
}

.-btn {
    color: #fff !important;
}

.-next {
    background-color: #6A214F;
    border-radius: 10px;
}

.-previous {
    background-color: #6A214F;
    border-radius: 10px;
}

.FensaBody table th {
    font-size: 1.2em;
    text-transform: none;
}


/*address look up*/
ul.multi-select-list.with-distinctive-items {
    border-radius: 10px;
    padding: 15px;
    background-color: #fff;
    /*font-size: 1em;*/
    line-height: 20px;
}


/*SIDEBAR NAV*/


li.MuiButtonBase-root-66:hover {
    background-color: #4189C7;
}


li.MuiButtonBase-root-66:active {
    background-color: #4189C7;
}

li.MuiButtonBase-root-66 a:visited {
    background-color: #4189C7;
}


ul.nav.navbar-nav > li {
    margin: 0;
}

.navbar-nav li:hover {
    background-color: #4189C7;
}

ul.nav.navbar-nav > li > a:hover {
    color: #fff;
}

.navbar .navbar-nav .active a, .navbar .navbar-nav .active a:hover, .navbar .navbar-nav .active a:focus {
    font-weight: bold;
}

.slider .btn {
    margin-left: 10px;
}

.verify {
    margin-top: 40px;
}

.verify-btn {
    margin-top: 25px;
}

ul.multi-select-list.with-distinctive-items {
    width: 100%;
}



.company-banner {
    background-color: #6A214F;
    color: #fff;
    font-size: 18px;
    padding-top: 10px;
    margin-bottom: 0;
}


/*laptop Responsive*/
@media only screen and (max-width: 1300px) {
    .product-type button {
        padding: 20px;
    }
}

/*Tablet Responsive*/
@media only screen and (max-width: 1024px) {
    .FensaBody {
        height: auto;
    }

    .FixedHeight {
        padding: 40px 0;
        width: 175px;
        height: 165px;
    }

    .fa-7x {
        font-size: 6em;
    }

    .col-md-4.product-type {
        margin-bottom: 10px;
    }
}


/*Mobile Responsive: was 500 but any screen res lower does not appear ok*/
@media only screen and (max-width: 768px) {
    .FensaBody {
        padding: 20px 20px;
    }

    .sign-in-container {
        padding-top: 2px !important;
        padding-bottom: 4px !important;
    }

    h2 {
        font-size: 1.4rem !important;
    }


    .FixedHeight {
        padding: 25px 0;
        height: 115px;
        width: 115px;
    }

    .fa-7x {
        font-size: 4em;
    }

    .headerClass img {
        display: none;
    }

    .notification {
        margin-right: 20px;
    }

    .sideBarMenuContainer {
        height: auto;
    }

    .form-control {
        margin-bottom: 12px;
    }

    a.btn.orange {
        margin-bottom: 10px;
    }

    .FensaBody h1 {
        text-align: center;
    }

    .navbar {
        margin-bottom: 0 !important;
    }

    .verify-btn {
        margin-top: 0;
    }

    .Banner {
        height: auto;
    }

    .headerClass {
        height: auto;
    }

    .Banner img {
        display: block;
        margin: auto;
    }

    .MenuFixedHeight {
        height: auto;
    }

    .SingleDatePicker {
        margin-bottom: 10px
    }

    .product-type img {
        width: 100%;
    }

    .numberwindows {
        margin-top: 20px;
        margin-left: 40px;
    }
}




.modal {
    font-size: 12px;
}

    .modal > .header {
        width: 100%;
        border-bottom: 1px solid gray;
        font-size: 18px;
        text-align: center;
        padding: 5px;
    }

    .modal > .content {
        width: 100%;
        padding: 10px 5px;
    }

    .modal > .actions {
        width: 100%;
        padding: 10px 5px;
        margin: auto;
        text-align: center;
    }

    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #ffffff;
        border-radius: 18px;
        border: 1px solid #cfcece;
    }


/*SLIDER*/

.slider {
    -webkit-appearance: none; /* Override default CSS styles */
    -moz-appearance: none;
         appearance: none;
    height: 10px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */ /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    margin-top: 10px;
}

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: #6A214F; /* Green background */
        cursor: pointer; /* Cursor on hover */
        border-radius: 50%;
    }

    .slider::-moz-range-thumb {
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: #6A214F; /* Green background */
        cursor: pointer; /* Cursor on hover */
        border-radius: 50%;
    }

ul.numbers {
    list-style: none;
    padding-left: 0;
}

.numbers li {
    float: left;
}

.numberwindows {
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    padding-left: 10px;
    height: 30px;
    margin-right: 10px;
}

/*CHES CSS*/
.card-body {
    margin-bottom: 20px;
}

.valid-completion-date {
    color: red;
    font-size: .775rem;
    margin-top: .7rem;
}

.DateInput {
    background: transparent !important;
}

.DateInput_input {
    background-color: transparent !important;
}

.SingleDatePickerInput__withBorder {
    margin-top: 23px;
}

.SingleDatePickerInput__withBorder {
    border: 0 none !important;
    border-bottom: 1px solid #dbdbdb !important;
}

.SingleDatePickerInput .DateInput .DateInput_input {
    font-size: 1rem !important;
    color: #757575;
    font-weight: 300;
}

.DateInput_input__focused {
    border-bottom: 2px solid #4189C7;
}


.card-item-wrapper {
    width: 350px;
}

.LogoCircle {
    margin-top: -26px;
}

.LogoCircle img {
    width: 80% !important;
}

.Benefits {
    padding: 18px 0;
}

    .Benefits img {
        width: 80% !important;
    }

iframe {
    width: 100%;
}


.Layout-sidebarImg-7 {
    max-height: 50px !important;
}

.add-btn {
    height: 50px;
}

.product {
    width: auto;
    margin-bottom: 10px;
}

.energy-label {
    width: 70%;
}

.benefits img {
    width: 50%;
}

.product-column img {
    width: 100%;
}

.rating-btn {
    width: 240px;
}

.lead {
    font-size: 1rem;
}

.jumbotron {
    padding: 1rem 0 !important;
    margin-top: 20px;
}

.DateInput_input {
    font-weight: 400;
}

.call-out {
    border-left: 0 !important;
}

.form-control {
    font-size: 0.9rem;
}

.rt-resizable-header-content {
    font-size: 0.9rem;
}

.rt-td {
    font-size: 1rem;
}

    .rt-td span {
        font-size: 1rem;
    }

.md-form label.active {
    font-weight: 500;
}

input.form-control::-webkit-input-placeholder {
    color: #000;
}

input.form-control::-ms-input-placeholder {
    color: #000;
}

input.form-control::placeholder {
    color: #000;
}



/*.product-type button {
    width: 50%;
    margin: auto;
    padding: 25px;
    height: 160px;
    display: block;
}*/

.product-type button {
    width: 34%;
    margin: auto;
    height: 110px;
    display: block;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    padding: 0 10px;
}


label {
    color: #6A214F;
}

.card .FixedHeight {
    margin: auto;
    width: 100px;
    height: 100px;
    padding: 20px 0;
}

.FixedHeight img {
    width: 40%;
}

.FixedHeightImage {
    width: 60%;
}

.local-address {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.15);
    box-shadow: 0 0 5px 1px #c9c9c9;
    margin-bottom: 20px
}

    .local-address h5 {
        color: #69214f
    }

    .local-address a {
        color: #69214f;
        padding-top: 10px;
        text-transform: uppercase
    }

.details a {
    font-size: 16px;
    color: #69214f
}

.details i {
    margin-right: 10px
}

.local-address i {
    color: #69214f;
    margin-left: 10px
}

.local-address .registered {
    background-color: #69214f
}

    .local-address .registered p {
        color: #fff;
        text-align: center;
        padding-top: 10px
    }

        .local-address .registered p span {
            font-weight: 700
        }

@media only screen and (max-width: 1200px) {
    .product-type button {
        width: 40% !important;
        height: 100px;
    }

    .product-type img {
        width: 50%;
    }

    .btn.btn-sm {
        font-size: 0.8rem;
    }

    .rating-btn {
        width: 190px;
    }

    .product {
        /*max-width: 33%;*/
    }

    .card-item-wrapper {
        width: 320px;
    }
}

@media only screen and (max-width: 1024px) {
    .product {
        width: 270px;
    }

    .card-item-wrapper {
        width: 300px;
    }
}

@media only screen and (max-width: 900px) {
    .card-wrapper {
        width: 50%;
    }
}


@media only screen and (max-width: 800px) {
    .pb-5, .py-5 {
        padding-bottom: 0 !important;
    }



    .jumbotron {
        padding: 1rem 1rem;
    }

        .jumbotron .px-5 {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }

    .lead {
        font-size: 1rem;
    }

    .product-type button {
        width: 60% !important;
        height: 100px !important;
    }

    .product-type img {
        width: 50% !important;
    }

    /*.md-form label.active {
            font-size: 0.7rem;
        }*/

    .md-form {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}


@media only screen and (max-width: 768px) {
    .product {
        width: 185px;
    }

    .btn-toolbar .btn {
        padding: 10px 20px;
    }

    .MuiTypography-h6-95 {
        font-size: 0.9rem !important;
    }

    .card-item-wrapper {
        width: 330px;
    }
}


/*iPHONE 8+*/
@media only screen and (max-width: 450px) {
    .Layout-content-16 {
        padding: 16px !important;
    }

    .lead {
        font-size: 1rem;
    }

    /*.lead {
            font-size: 0.8rem;
        }*/

    .btn.orange {
        padding: 0.84rem 0.9rem;
    }

    .pl-5, .px-5 {
        padding-left: 1rem !important;
    }

    .pb-5, .py-5 {
        padding-bottom: 4rem !important;
    }

    .pr-5, .px-5 {
        padding-right: 1rem !important;
    }

    .fa-7x {
        font-size: 3em;
        padding-top: 10px;
    }

    .FixedHeight {
        width: 115px;
        height: 115px;
        padding: 25px 0;
    }

        .FixedHeight img {
            width: 40px;
        }

    .form-group-fluid-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .btn-group {
        display: inline-grid;
        width: 100%;
    }

    .product-type button {
        width: 46% !important;
        margin: auto;
        padding: 15px;
        height: 90px !important;
        display: block;
    }

    .product-type img {
        width: 70% !important;
    }

    .invalid-feedback {
        display: none !important;
    }

    .card-wrapper {
        width: 100% !important;
    }

    /*.card-item-wrapper {
            min-width: 100% !important;
        }*/

    .Benefits img {
        width: 60%;
        height: auto;
    }

    .energy-label {
        width: 70%;
    }

    .product-type .product-small {
        width: 55px !important;
        margin-right: auto;
        margin-left: auto;
        display: block;
        float: none;
    }

    .non-fensa {
        text-align: center;
    }

    .slider#NoneFensaWindows {
        margin-top: 0 !important;
        /*margin-left: 60px;*/
    }

    .slider#NoneFensaDoors {
        margin-top: 0 !important;
        /*margin-left: 60px;*/
    }

    .product {
        max-width: 100%;
    }

    .slider {
        width: 100%;
    }

    .MuiSlider-thumb {
        background-color: green !important;
        color: #6A214F !important;
    }

    .Layout-menuButton-10 {
        margin-right: 0 !important;
    }

    .MuiTypography-h6-95 {
        font-size: 0.8rem !important;
    }

    input#NonFensaWindows {
        margin-left: 0 !important;
    }

    input#NonFensaDoors {
        margin-left: 0 !important;
    }

    .verify-button {
        padding: 0.5rem 0.5rem !important;
    }

    .Benefits {
        padding: 12px 0;
    }

    .card-item-wrapper {
        width: 100%;
    }

    .add-btn {
        width: 70%;
        margin: 0;
        padding: 0 !important;
    }

    .md-form label {
        font-size: 0.8rem;
    }
}

input#NonFensaWindows {
    margin-left: 25px;
}

input#NonFensaDoors {
    margin-left: 25px;
}


.slider#NoneFensaWindows {
    margin-top: 40px;
}

.slider#NoneFensaDoors {
    margin-top: 40px;
}

/*iPHONE 8*/
@media only screen and (max-width: 400px) {
    a.card-link {
        font-size: 0.8em;
    }

    .product {
        width: 100%;
    }

    .form-group-fluid-container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .product-type button {
        height: 95px !important;
    }

    .h3, h3 {
        font-size: 1rem;
    }

    .pl-5 {
        padding-left: 0 !important;
    }

    .header img {
        width: 100px;
    }

    button.jss10 {
        margin-right: 10px;
    }

    header h6.jss78 {
        font-size: 0.8em;
    }

    header h1.jss78 {
        font-size: 0.8rem;
    }
}




/*INTERNET EXPLORER 10+*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

    .slider {
        display: none;
    }

    .product {
        width: 333px;
    }

    .col-xs-12 {
        width: 100%;
    }
}



.box {
    width: 100%;
    margin: 0 auto;
    background: #6A214F;
    padding: 15px;
    background-clip: padding-box;
    border-radius: 5px;
}

    .box p {
        color: #fff;
    }

    .box .button {
        background-color: #fff;
        font-size: 1em;
        padding: 10px;
        color: #6A214F;
        border-radius: 20px;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease-out;
    }

        .box .button:hover {
            background: #ff6a00;
            color: #fff;
        }

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}

    .overlay:target {
        visibility: visible;
        opacity: 1;
        z-index: 10000;
    }

.popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 70%;
    position: relative;
    transition: all 5s ease-in-out;
}

    .popup h2 {
        margin-top: 0;
        color: #333;
    }

    .popup .close {
        position: absolute;
        top: 20px;
        right: 30px;
        transition: all 200ms;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        color: #333;
    }

        .popup .close:hover {
            color: #6A214F;
        }

    .popup .content {
        max-height: 30%;
        overflow: auto;
    }

@media screen and (max-width: 700px) {
    .box {
        width: 100%;
    }

    .popup {
        width: 90%;
    }
}


.faux-primary-btn {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    padding: 5px;
    border-radius: 3px;
}

.faux-secondary-btn {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    padding: 5px;
    border-radius: 3px;
}

.faux-success-btn {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    padding: 5px;
    border-radius: 3px;
}

.faux-danger-btn {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    padding: 5px;
    border-radius: 3px;
}

.faux-warning-btn {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
    padding: 5px;
    border-radius: 3px;
}

.faux-info-btn {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    padding: 5px;
    border-radius: 3px;
}

.faux-dark-btn {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    padding: 5px;
    border-radius: 3px;
}

.faux-light-btn {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    padding: 5px;
    border-radius: 3px;
}

.selected-toggle {
    padding-bottom: 1px !important;
    border-bottom: 3px #17a2b8 solid;
}

.table-shadowed {
    box-shadow: 0 1px 5px 0.2rem rgba(0, 0, 0, 0.2);
}

.call-out {
    border-left: 5px solid;
}

/*THIS IS A HACK FOR REACT PAGING*/
.ReactTable {
    border-radius: 3px !important;
}

    .ReactTable .rt-th {
        padding: 8px !important;
    }

.-previous {
    -webkit-flex-grow: 0.05 !important;
            flex-grow: 0.05 !important;
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
    -webkit-flex-basis: 0% !important;
            flex-basis: 0% !important;
}

.-next {
    -webkit-flex-grow: 0.07 !important;
            flex-grow: 0.07 !important;
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
    -webkit-flex-basis: 0% !important;
            flex-basis: 0% !important;
}

.-center {
    -webkit-flex-grow: 0.3 !important;
            flex-grow: 0.3 !important;
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
    -webkit-flex-basis: 0% !important;
            flex-basis: 0% !important;
}

.text-link {
    cursor: pointer;
}





.card-item-wrapper {
    padding: 10px 15px;
    /*margin: 10px 10px;*/
}

    /*Truncate text to 220px*/
    .card-item-wrapper .card-item-text {
        width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.card-item-highlight {
    /*border: 1px solid rgba(10, 50, 150, 0.5);*/
    box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);
}

.card-user-highlight {
    /*border: 1px solid rgba(10, 50, 150, 0.5);*/
    box-shadow: 0 1px 5px 0.2rem rgba(54, 103, 50, 0.8);
}

.main-branch {
    /*border: 1px solid rgba(10, 50, 150, 0.5);*/
    box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);
}

.main-contact {
    /*border: 1px solid rgba(10, 50, 150, 0.5);*/
    box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);
}

/* HACK FOR FORM GROUP CONTAINER*/
.demoForm {
    /*width: 750px;*/
}

.form-group-container {
    border-color: #444444;
    border-radius: 5px;
    /*padding: 30px;*/
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    box-shadow: 0 1px 5px 0.3rem rgba(0, 0, 0, 0.2);
    margin-left: auto !important;
    margin-right: auto !important;
    width: 750px;
}

    .form-group-container .form-group-title {
        font-family: inherit;
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        margin-top: 20px;
        margin-bottom: 30px;
    }

.pb-3, .py-3 {
    padding-bottom: 0rem !important;
}

.vi-6 {
    margin: -15px 0 0 100px;
    padding-bottom: 1rem !important;
}

.row {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 5px;
}

.grey-warning {
    color: #828587;
}

@media(max-width: 577px) {
    .form-group-container {
        border-color: #444444;
        border-radius: 5px;
        padding: 30px;
        margin-bottom: 20px;
        box-shadow: 0 1px 5px 0.3rem rgba(0, 0, 0, 0.2);
        width: 100%;
        max-width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
    }

        .form-group-container .form-group-title {
            font-family: inherit;
            font-size: 2.0rem;
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
            margin-top: 10px;
            margin-bottom: 20px;
        }
}
/*THIS IS A HACK FOR BOOTSTRAP 4*/
/*.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}


.form-inline .input-group,
.form-inline .custom-select {
    width: auto;
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

    .input-group > .form-control,
    .input-group > .form-control-plaintext,
    .input-group > .custom-select,
    .input-group > .custom-file {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
    }

        .input-group > .form-control + .form-control,
        .input-group > .form-control + .custom-select,
        .input-group > .form-control + .custom-file,
        .input-group > .form-control-plaintext + .form-control,
        .input-group > .form-control-plaintext + .custom-select,
        .input-group > .form-control-plaintext + .custom-file,
        .input-group > .custom-select + .form-control,
        .input-group > .custom-select + .custom-select,
        .input-group > .custom-select + .custom-file,
        .input-group > .custom-file + .form-control,
        .input-group > .custom-file + .custom-select,
        .input-group > .custom-file + .custom-file {
            margin-left: -1px;
        }

        .input-group > .form-control:focus,
        .input-group > .custom-select:focus,
        .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
            z-index: 3;
        }

        .input-group > .custom-file .custom-file-input:focus {
            z-index: 4;
        }

        .input-group > .form-control:not(:last-child),
        .input-group > .custom-select:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .input-group > .form-control:not(:first-child),
        .input-group > .custom-select:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

    .input-group > .custom-file {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
    }

        .input-group > .custom-file:not(:last-child) .custom-file-label,
        .input-group > .custom-file:not(:last-child) .custom-file-label::after {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .input-group > .custom-file:not(:first-child) .custom-file-label {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

.input-group-prepend,
.input-group-append {
    display: -ms-flexbox;
    display: flex;
}

    .input-group-prepend .btn,
    .input-group-append .btn {
        position: relative;
        z-index: 2;
    }

        .input-group-prepend .btn:focus,
        .input-group-append .btn:focus {
            z-index: 3;
        }

        .input-group-prepend .btn + .btn,
        .input-group-prepend .btn + .input-group-text,
        .input-group-prepend .input-group-text + .input-group-text,
        .input-group-prepend .input-group-text + .btn,
        .input-group-append .btn + .btn,
        .input-group-append .btn + .input-group-text,
        .input-group-append .input-group-text + .input-group-text,
        .input-group-append .input-group-text + .btn {
            margin-left: -1px;
        }

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

    .input-group-text input[type="radio"],
    .input-group-text input[type="checkbox"] {
        margin-top: 0;
    }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
    padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-body {
    color: #212529 !important;
}

.text-muted {
    color: #6c757d !important;
}

/*HACK FOR BORDERS * /
.border {
    border: 1px solid #dee2e6 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
    border-left: 1px solid #dee2e6 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #007bff !important;
}

.border-secondary {
    border-color: #6c757d !important;
}

.border-success {
    border-color: #28a745 !important;
}

.border-info {
    border-color: #17a2b8 !important;
}

.border-warning {
    border-color: #ffc107 !important;
}

.border-danger {
    border-color: #dc3545 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #343a40 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-print-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}


/*HACK FOR Background* /
.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #1d2124 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

/* HACK FOR BUTTONS* /
.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

    .btn-secondary:hover {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
    }

    .btn-secondary:focus, .btn-secondary.focus {
        box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
    }

    .btn-secondary.disabled, .btn-secondary:disabled {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
    }

    .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
        color: #fff;
        background-color: #545b62;
        border-color: #4e555b;
    }

        .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-secondary.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
        }

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

    .btn-info:hover {
        color: #fff;
        background-color: #138496;
        border-color: #117a8b;
    }

    .btn-info:focus, .btn-info.focus {
        box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
    }

    .btn-info.disabled, .btn-info:disabled {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8;
    }

    .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
    .show > .btn-info.dropdown-toggle {
        color: #fff;
        background-color: #117a8b;
        border-color: #10707f;
    }

        .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
        .show > .btn-info.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
        }

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

    .btn-warning:hover {
        color: #212529;
        background-color: #e0a800;
        border-color: #d39e00;
    }

    .btn-warning:focus, .btn-warning.focus {
        box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
    }

    .btn-warning.disabled, .btn-warning:disabled {
        color: #212529;
        background-color: #ffc107;
        border-color: #ffc107;
    }

    .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
    .show > .btn-warning.dropdown-toggle {
        color: #212529;
        background-color: #d39e00;
        border-color: #c69500;
    }

        .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
        .show > .btn-warning.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
        }

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

    .btn-danger:hover {
        color: #fff;
        background-color: #c82333;
        border-color: #bd2130;
    }

    .btn-danger:focus, .btn-danger.focus {
        box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
    }

    .btn-danger.disabled, .btn-danger:disabled {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;
    }

    .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
    .show > .btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #bd2130;
        border-color: #b21f2d;
    }

        .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
        .show > .btn-danger.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
        }

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

    .btn-light:hover {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5;
    }

    .btn-light:focus, .btn-light.focus {
        box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
    }

    .btn-light.disabled, .btn-light:disabled {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
    }

    .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
    .show > .btn-light.dropdown-toggle {
        color: #212529;
        background-color: #dae0e5;
        border-color: #d3d9df;
    }

        .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
        .show > .btn-light.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
        }

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

    .btn-dark:hover {
        color: #fff;
        background-color: #23272b;
        border-color: #1d2124;
    }

    .btn-dark:focus, .btn-dark.focus {
        box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
    }

    .btn-dark.disabled, .btn-dark:disabled {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40;
    }

    .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
    .show > .btn-dark.dropdown-toggle {
        color: #fff;
        background-color: #1d2124;
        border-color: #171a1d;
    }

        .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
        .show > .btn-dark.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
        }

.btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
}

    .btn-outline-primary:hover {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }

    .btn-outline-primary:focus, .btn-outline-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }

    .btn-outline-primary.disabled, .btn-outline-primary:disabled {
        color: #007bff;
        background-color: transparent;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }

        .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-primary.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
        }

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
}

    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
    }

    .btn-outline-secondary:focus, .btn-outline-secondary.focus {
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    }

    .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
        color: #6c757d;
        background-color: transparent;
    }

    .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-secondary.dropdown-toggle {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
    }

        .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-secondary.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
        }

.btn-outline-success {
    color: #28a745;
    border-color: #28a745;
}

    .btn-outline-success:hover {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
    }

    .btn-outline-success:focus, .btn-outline-success.focus {
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
    }

    .btn-outline-success.disabled, .btn-outline-success:disabled {
        color: #28a745;
        background-color: transparent;
    }

    .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
    .show > .btn-outline-success.dropdown-toggle {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
    }

        .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-success.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
        }

.btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
}

    .btn-outline-info:hover {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8;
    }

    .btn-outline-info:focus, .btn-outline-info.focus {
        box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    }

    .btn-outline-info.disabled, .btn-outline-info:disabled {
        color: #17a2b8;
        background-color: transparent;
    }

    .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
    .show > .btn-outline-info.dropdown-toggle {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8;
    }

        .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-info.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
        }

.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
}

    .btn-outline-warning:hover {
        color: #212529;
        background-color: #ffc107;
        border-color: #ffc107;
    }

    .btn-outline-warning:focus, .btn-outline-warning.focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    }

    .btn-outline-warning.disabled, .btn-outline-warning:disabled {
        color: #ffc107;
        background-color: transparent;
    }

    .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
    .show > .btn-outline-warning.dropdown-toggle {
        color: #212529;
        background-color: #ffc107;
        border-color: #ffc107;
    }

        .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-warning.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
        }

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
}

    .btn-outline-danger:hover {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;
    }

    .btn-outline-danger:focus, .btn-outline-danger.focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    }

    .btn-outline-danger.disabled, .btn-outline-danger:disabled {
        color: #dc3545;
        background-color: transparent;
    }

    .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
    .show > .btn-outline-danger.dropdown-toggle {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;
    }

        .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-danger.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
        }

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

    .btn-outline-light:hover {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
    }

    .btn-outline-light:focus, .btn-outline-light.focus {
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    }

    .btn-outline-light.disabled, .btn-outline-light:disabled {
        color: #f8f9fa;
        background-color: transparent;
    }

    .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
    .show > .btn-outline-light.dropdown-toggle {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
    }

        .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-light.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
        }

.btn-outline-dark {
    color: #343a40;
    border-color: #343a40;
}

    .btn-outline-dark:hover {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40;
    }

    .btn-outline-dark:focus, .btn-outline-dark.focus {
        box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    }

    .btn-outline-dark.disabled, .btn-outline-dark:disabled {
        color: #343a40;
        background-color: transparent;
    }

    .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
    .show > .btn-outline-dark.dropdown-toggle {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40;
    }

        .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
        .show > .btn-outline-dark.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
        }

.btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}

    .btn-link:hover {
        color: #0056b3;
        text-decoration: underline;
    }

    .btn-link:focus, .btn-link.focus {
        text-decoration: underline;
        box-shadow: none;
    }

    .btn-link:disabled, .btn-link.disabled {
        color: #6c757d;
        pointer-events: none;
    }

.btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

    .btn-block + .btn-block {
        margin-top: 0.5rem;
    }*/


.sideBarMenuContainer {
    /*position: fixed;*/
    /*height: 100vh;*/
    /*height: 100%;*/
    /*background-color: #494C4E;*/
}

.menu-item-icon {
    padding-right: 18px !important;
    /*font-size: 1.5rem!important;*/
    font-size: 1.6em !important;
    width: 20px !important;
}

.navbar-custom {
    background-color: #494C4E;
}

ul.nav.navbar-nav > li > a {
    color: #fff;
    text-transform: uppercase;
}

ul.nav.navbar-nav > li {
    border-bottom: 1px solid #DDDDDC;
}


.navbar li .glyphicon {
    margin-right: 20px;
}

/* Highlighting rules for nav menu items */
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-image: none;
    background-color: #4189C7;
    color: #fff;
}

.FensaBody{
    background-color: #F2F2F2;
}

.headerClass {
    background-color: #fff;
    height: 30px;
}

.Banner {
    background-color: #fff;
    height: 140px;
}

@media (min-width: 768px) {
    /* On large screens, convert the nav menu to a vertical sidebar */
    .navbar {
        height: 100%;
        width: calc(16.67%);
    }
    
    /*.navbar-fixed-top {
        top: 130px;
    }*/

    .navbar {
        border-radius: 0;
        border-width: 0;
        height: 100%;
        width: 100%;
    }
    .navbar-header {
        float: none;
    }
    .navbar .navbar-collapse {
        border-top: 1px solid #444;
        padding: 0;
    }
    .navbar .container-fluid {
        padding: 0;
        margin: 0;
    }
    .navbar .container-fluid .navbar-brand {
        margin: 0;
    }
    .navbar ul {
        float: none;
    }
    .navbar li {
        float: none;
        font-size: 15px;
        margin: 6px;
    }
    .navbar li a {
        padding: 10px 16px;
        border-radius: 4px;
    }
    .navbar a {
        /* If a menu item's text is too long, truncate it */
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.please-wait {
    /*text-align: right;*/
}

.show {
    display: block;
}

.hide {
    display: none;
}

.please-wait-logo {
    -webkit-animation: please-wait-logo-spin infinite 8s linear;
            animation: please-wait-logo-spin infinite 8s linear;
    height: 5vmin;
    pointer-events: none;
}

@-webkit-keyframes please-wait-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes please-wait-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.signin-icon {
    max-width: 180px;
    padding-bottom: 15px;
    margin-top: 25px;
}

.paper {
    margin-top: 28px;
    /*display: flex;*/
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 3rem;
}
.large {
    font-size:80px;
}

a.btn.orange {
    background-color: #C8602D;
    color: #fff;
    text-transform: uppercase;
    margin-right: 10px;
    margin-top: 10px;
}


.CmpnyVideo {
    position: absolute; /* Positioning and size */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128,128,128,0.5); /* color */
    z-index:1;
    cursor: pointer;
  }
  
  
  .iframe-container {
      position: absolute;
     width:50%;
     height:50%;
      top: 40%;
      left:20%;
      right: 20%;
      bottom: 20%; 
      margin: auto;
      padding: 1rem;
      background-color: rgba(0,0,0, 0.5);
      z-index:1;
      
    }
.popup {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 1rem;
    background-color: rgba(0,0,0, 0.5);
    z-index:1;
    
    
  }
  
  .popup_inner {
    position: fixed;
    left: 35%;
    right: 25%;
    top: 50%;
    bottom: auto;
    margin: auto;
    background: white;
    padding: 1rem;
    cursor: pointer;
    min-height: 10vh;
    overflow-y: auto;
  }
  
  
  .btn {
    margin: 0 0 0 150px;
    
  }
  
  .btnweb {
  margin: 0 0 0 200px;
  text-transform:uppercase;
  border:0 ;
  padding: 0.84rem 2.14rem;
  font-size: 0.81rem;
  }
  
  
  .circle {
  border-radius: 64px;
  background: #85286c;
  color: white;
  font-weight: bold;
  }
  
  
  .buttons {
  
  margin: 0 auto;
  display: inline;}
     
  .action_btn {
  
  margin: 0px 0 0 60px; 
  display: inline;
  position: absolute;}
  
  .close{
    margin-right:-28px;
    margin-top:-20px;
  }
.react-datepicker {
    font-size: 1em;
}

.react-datepicker__header {
    padding-top: 0.8em;
}

.react-datepicker__month {
    margin: 0.4em 1em;
}

.react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}

.react-datepicker__current-month {
    font-size: 1em;
}

.react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}

.answer-button, .image-button, .yes-no-button, .number-picker-button {
    color: #777;
    background: #fff;
    border-color: #c6c6c6;
}

/*@media all and (min-width:641px)*/
.answer-button, .image-button {
    padding: 14px 20px 14px 20px;
    width: 180px;
    height: 60px;
}
    .answer-button.selected:hover, .image-button.selected:hover, .yes-no-button.selected:hover, .number-picker-button.selected:hover {
        color: #fff;
        background: #38a83d;
        border-color: #008014;
    }
    .answer-button.selected:hover, .image-button.selected:hover, .number-picker-button.selected:hover {
        box-shadow: 0px 5px 0px 0px #008014;
    }

    .btn.btn-default.active{
        background-color:cornflowerblue;
        color:white;
    }


/*.active {
    background-color: cornflowerblue;
    color: white;
}*/

.multi-select-list.with-distinctive-items {
    max-height: 225px;
    background: #c6c6c6;
    margin-bottom: 20px;
    font-size: 0.87em;
}

ul.multi-select-list {
    overflow-y: auto;
    background: #fff;
    list-style-type: none;
    padding-left: 0px;
    border: 1px solid #c6c6c6;
    margin-top: 0px;
}
/*@media all and (min-width:641px)*/
ul.multi-select-list {
    width: 550px;
    max-height: 400px;
}

.date {
    margin-top: 10px;
}


.SingleDatePickerInput .DateInput {
    width: 100%;
}

.SingleDatePicker {
    width: 100%;
}

.SingleDatePickerInput {
    width: 100%;
}

.SingleDatePickerInput .DateInput .DateInput_input {
    font-size: 14px;
    padding: 6px 11px;
}

.DateInput .SingleDatePicker_picker {
    z-index: 2;
}

.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2.SingleDatePickerInput__showClearDate.SingleDatePickerInput__showClearDate_3 {
    padding-right: 0;
}

.react-datepicker {
    font-size: 1em;
}

.react-datepicker__header {
    padding-top: 0.8em;
}

.react-datepicker__month {
    margin: 0.4em 1em;
}

.react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}

.react-datepicker__current-month {
    font-size: 1em;
}

.react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}

.answer-button, .image-button, .yes-no-button, .number-picker-button {
    color: #777;
    background: #fff;
    border-color: #c6c6c6;
}

/*@media all and (min-width:641px)*/
.answer-button, .image-button {
    padding: 14px 20px 14px 20px;
    width: 180px;
    height: 60px;
}
    .answer-button.selected:hover, .image-button.selected:hover, .yes-no-button.selected:hover, .number-picker-button.selected:hover {
        color: #fff;
        background: #38a83d;
        border-color: #008014;
    }
    .answer-button.selected:hover, .image-button.selected:hover, .number-picker-button.selected:hover {
        box-shadow: 0px 5px 0px 0px #008014;
    }

    .btn.btn-default.active{
        background-color:cornflowerblue;
        color:white;
    }


/*.active {
    background-color: cornflowerblue;
    color: white;
}*/

.multi-select-list.with-distinctive-items {
    max-height: 225px;
    background: #c6c6c6;
    margin-bottom: 20px;
    font-size: 0.87em;
}

ul.multi-select-list {
    overflow-y: auto;
    background: #fff;
    list-style-type: none;
    padding-left: 0px;
    border: 1px solid #c6c6c6;
    margin-top: 0px;
}
/*@media all and (min-width:641px)*/
ul.multi-select-list {
    width: 550px;
    max-height: 400px;
}

.date {
    margin-top: 10px;
}


.SingleDatePickerInput .DateInput {
    width: 100%;
}

.SingleDatePicker {
    width: 100%;
}

.SingleDatePickerInput {
    width: 100%;
}

.SingleDatePickerInput .DateInput .DateInput_input {
    font-size: 14px;
    padding: 6px 11px;
}

.DateInput .SingleDatePicker_picker {
    z-index: 2;
}

.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2.SingleDatePickerInput__showClearDate.SingleDatePickerInput__showClearDate_3 {
    padding-right: 0;
}

p {
    font-size: 16px;
    color: #4f5963;
    margin-bottom: 0px;
    font-weight: normal;
}

h5 {
    color: #69214f;
    font-weight: 700;
    font-size: 18px;
    font-family: "Myriad Pro","Trebuchet MS","Gill Sans","Gill Sans MT",Calibri,sans-serif;
}

body {
}

table th {
    color: #6A214F;
    font-size: 1.4em;
    font-weight: 100;
    text-transform: uppercase;
    padding: 5px 0 5px 10px;
}

table .row {
    border-bottom: 1px solid #eee;
    padding-top: 8px;
    padding-left: 10px;
}


.react-datepicker {
    font-size: 1em;
}

.react-datepicker__header {
    padding-top: 0.8em;
}

.react-datepicker__month {
    margin: 0.4em 1em;
}

.react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}

.react-datepicker__current-month {
    font-size: 1em;
}

.react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}

.answer-button, .image-button, .yes-no-button, .number-picker-button {
    color: #777;
    background: #fff;
    border-color: #c6c6c6;
}

/*@media all and (min-width:641px)*/
.answer-button, .image-button {
    padding: 14px 20px 14px 20px;
    width: 180px;
    height: 60px;
}
    .answer-button.selected:hover, .image-button.selected:hover, .yes-no-button.selected:hover, .number-picker-button.selected:hover {
        color: #fff;
        background: #38a83d;
        border-color: #008014;
    }
    .answer-button.selected:hover, .image-button.selected:hover, .number-picker-button.selected:hover {
        box-shadow: 0px 5px 0px 0px #008014;
    }

    .btn.btn-default.active{
        background-color:cornflowerblue;
        color:white;
    }


/*.active {
    background-color: cornflowerblue;
    color: white;
}*/

.multi-select-list.with-distinctive-items {
    max-height: 225px;
    background: #c6c6c6;
    margin-bottom: 20px;
    font-size: 0.87em;
}

ul.multi-select-list {
    overflow-y: auto;
    background: #fff;
    list-style-type: none;
    padding-left: 0px;
    border: 1px solid #c6c6c6;
    margin-top: 0px;
}
/*@media all and (min-width:641px)*/
ul.multi-select-list {
    width: 550px;
    max-height: 400px;
}

.date {
    margin-top: 10px;
}


.SingleDatePickerInput .DateInput {
    width: 100%;
}

.SingleDatePicker {
    width: 100%;
}

.SingleDatePickerInput {
    width: 100%;
}

.SingleDatePickerInput .DateInput .DateInput_input {
    font-size: 14px;
    padding: 6px 11px;
}

.DateInput .SingleDatePicker_picker {
    z-index: 2;
}

.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2.SingleDatePickerInput__showClearDate.SingleDatePickerInput__showClearDate_3 {
    padding-right: 0;
}

body {
}

table th {
    color: #6A214F;
    font-size: 1.4em;
    font-weight: 100;
    text-transform: uppercase;
    padding: 5px 0 5px 10px;
}

table .row {
    border-bottom: 1px solid #eee;
    padding-top: 8px;
    padding-left: 10px;
}

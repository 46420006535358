.please-wait {
    /*text-align: right;*/
}

.show {
    display: block;
}

.hide {
    display: none;
}

.please-wait-logo {
    animation: please-wait-logo-spin infinite 8s linear;
    height: 5vmin;
    pointer-events: none;
}

@keyframes please-wait-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
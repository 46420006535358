p {
    font-size: 16px;
    color: #4f5963;
    margin-bottom: 0px;
    font-weight: normal;
}

h5 {
    color: #69214f;
    font-weight: 700;
    font-size: 18px;
    font-family: "Myriad Pro","Trebuchet MS","Gill Sans","Gill Sans MT",Calibri,sans-serif;
}

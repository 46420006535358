.cookieConsent {
    z-index: 10000 !important;
}

.notice-message {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}

.fa-7x {
    font-size: 6em;
}

/*.fa-check{
    color:green;
}*/

.fa-times {
    color: red;
}

.card-wrapper {
    width: 33%;
}

.card-item-wrapper {
    padding: 10px 15px;
    line-height: 1.31 !important;
    font-size: 0.85rem;
}

    /*Truncate text to 220px*/
    .card-item-wrapper .card-item-text {
        width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.card-item-highlight {
    /*border: 1px solid rgba(10, 50, 150, 0.5);*/
    box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);
}

.product-type .product-small {
    width: 35px !important;
    float: left;
    margin-right: 10px;
    /*max-height: 35px !important;*/
}

.small-slider {
    width: 80px !important;
}

.invalid-input {
    position: absolute;
    top: 100%;
    z-index: 5;
    max-width: 100%;
    padding-left: .5rem;
    padding-top: .1rem;
    padding-right: .5rem;
    padding-bottom: .5rem;
    margin-top: .8rem;
    font-size: .775rem;
    line-height: 1;
    color: #ff0000;
    border-radius: .2rem;
}

/*.main-branch {
    /*border: 1px solid rgba(10, 50, 150, 0.5);* /
    box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);
}

.main-contact {
    /*border: 1px solid rgba(10, 50, 150, 0.5);* /
    box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);
}*/

.headerClass {
    height: 20px;
}

    .headerClass img {
        width: 70px;
        margin-top: 5px;
    }

.row {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
}

.FensaBody {
    padding: 20px 50px;
    min-height: 100vh;
}

.Banner {
    height: 120px;
}

    .Banner img {
        width: 160px;
    }

.img-banner {
    width: 80px;
}

.small2 {
    padding-left: 0;
    padding-right: 0;
    /*min-height: 100vh;*/
    min-height: 100%;
}

.workarea-bg {
    background-color: #444444 !important;
}

.workarea {
    background-color: #F2F2F2 !important;
}

.sign-in-container {
    /*padding-top: 120px !important;
    padding-bottom: 40px !important;*/
}

    .sign-in-container h2 {
        text-align: center;
    }

.avatar {
    max-width: 20px !important;
}

.form-check-input {
    margin-left: 0;
}

.Layout-drawerPaperClose-14 {
    width: 60px !important;
}

/*circle icons*/
.FixedHeight {
    background-color: #e6e6e6;
    border-radius: 50%;
    padding: 40px 0;
    display: inline-block;
    width: 180px;
    height: 180px;
}

    .FixedHeight:hover {
        /*background-color: rgba(106, 33, 79, 0.20);*/
        background-color: #fff;
    }




.MenuFixedHeight {
    /*height: 300px;*/
}

.Centre {
    text-align: center;
    color: #4d4d4d;
    margin-top: 10px;
}

    .Centre h2 {
        font-size: 19px;
        padding-top: 10px;
    }

.fa-7x {
    color: #6A214F;
}

.text-link {
    cursor: pointer;
}

/*h1*/
.FensaBody h1 {
    color: #6A214F;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
}

.login {
    background-color: #e6e6e6;
}

/*Dropdown user login*/

button.btn.btn-user-button {
    background-color: #e6e6e6;
    color: #6A214F;
    border-radius: 8px;
    border: 1px solid #fff;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 80px;
}

span.glyphicon.glyphicon-user {
    color: #6A214F;
}

ul.super-colors.dropdown-menu {
    background-color: #e6e6e6;
    color: #6A214F;
}

.dropdown-menu .divider {
    background-color: #fff;
}

.action-button {
    color: #6A214F;
    margin-top: 15px;
    border: none;
    font-size: 30px;
}

/*NOTIFICATION*/
.notification {
    color: white;
    text-decoration: none;
    position: relative;
    border-radius: 2px;
    margin-right: 30px;
}

    .notification .badge {
        position: absolute;
        top: 10px;
        right: -10px;
        padding: 7px 10px;
        border-radius: 50%;
        background: red;
        color: white;
    }

/*.text-danger {
    display: none;
}*/

.demoForm .row {
    margin-bottom: 10px;
}



.product-type .active {
    background-color: #fff;
    border: 2px solid #6A214F;
    color: #6A214F;
    font-weight: bold;
}

.product-type img {
    width: 50%;
    margin-bottom: 5px;
}

.btn-group p {
    text-align: center;
    margin-top: 10px;
}


.product-column {
    /*background-color: #fff;*/
    /*color: #6A214F;*/
    /*border-left: 1px solid rgba(95, 100, 109, 0.2);*/
    height: auto;
    overflow-y: auto;
    /*box-shadow: 0 1px 5px 0.2rem rgba(106, 33, 79, 0.8);*/
    margin-bottom: 25px !important;
    padding-top: 8px !important;
    /*padding-left: 35px !important;*/
}

.btn-normal-size {
    font-size: 0.99rem !important;
    font-weight: 400 !important;
}

    .btn-normal-size i {
        font-size: 1.45rem !important;
    }

.fa-icon-normal-size {
    font-size: 1.45rem !important;
}

.mdb-btn-resize {
    font-size: 0.85rem !important;
}

.look-up {
    /*margin-top: 5px;*/
}


.form-group-container {
    border-color: #444444;
    border-radius: 5px;
    /*padding: 30px;*/
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    box-shadow: 0 1px 5px 0.3rem rgba(0, 0, 0, 0.2);
    margin-left: auto !important;
    margin-right: auto !important;
    /*width: 750px;*/
    width: 100% !important;
}

    .form-group-container .form-group-title {
        font-family: inherit;
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        margin-top: 20px;
        margin-bottom: 30px;
    }

.form-group-fluid-container {
    background-color: #fff;
    border-color: #444444;
    border-radius: 5px;
    /*padding: 30px;*/
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    box-shadow: 0 1px 5px 0.3rem rgba(0, 0, 0, 0.2);
    margin-left: auto !important;
    margin-right: auto !important;
    width: auto;
}

    .form-group-fluid-container .form-group-title {
        font-family: inherit;
        /*font-size: 2.5rem;*/
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        margin-top: 20px;
        margin-bottom: 30px;
    }

@media(max-width: 577px) {
    .form-group-container {
        border-color: #444444;
        border-radius: 5px;
        padding: 30px;
        margin-bottom: 20px;
        box-shadow: 0 1px 5px 0.3rem rgba(0, 0, 0, 0.2);
        width: 100%;
        max-width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
    }

        .form-group-container .form-group-title {
            font-family: inherit;
            /*font-size: 2.0rem;*/
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
            margin-top: 10px;
            margin-bottom: 20px;
        }
}



/*tables*/

.ReactTable {
    border-radius: 10px;
}

.rt-resizable-header-content {
    color: #6A214F;
    font-weight: 600;
}

.-btn {
    color: #fff !important;
}

.-next {
    background-color: #6A214F;
    border-radius: 10px;
}

.-previous {
    background-color: #6A214F;
    border-radius: 10px;
}

.FensaBody table th {
    font-size: 1.2em;
    text-transform: none;
}


/*address look up*/
ul.multi-select-list.with-distinctive-items {
    border-radius: 10px;
    padding: 15px;
    background-color: #fff;
    /*font-size: 1em;*/
    line-height: 20px;
}


/*SIDEBAR NAV*/


li.MuiButtonBase-root-66:hover {
    background-color: #4189C7;
}


li.MuiButtonBase-root-66:active {
    background-color: #4189C7;
}

li.MuiButtonBase-root-66 a:visited {
    background-color: #4189C7;
}


ul.nav.navbar-nav > li {
    margin: 0;
}

.navbar-nav li:hover {
    background-color: #4189C7;
}

ul.nav.navbar-nav > li > a:hover {
    color: #fff;
}

.navbar .navbar-nav .active a, .navbar .navbar-nav .active a:hover, .navbar .navbar-nav .active a:focus {
    font-weight: bold;
}

.slider .btn {
    margin-left: 10px;
}

.verify {
    margin-top: 40px;
}

.verify-btn {
    margin-top: 25px;
}

ul.multi-select-list.with-distinctive-items {
    width: 100%;
}



.company-banner {
    background-color: #6A214F;
    color: #fff;
    font-size: 18px;
    padding-top: 10px;
    margin-bottom: 0;
}


/*laptop Responsive*/
@media only screen and (max-width: 1300px) {
    .product-type button {
        padding: 20px;
    }
}

/*Tablet Responsive*/
@media only screen and (max-width: 1024px) {
    .FensaBody {
        height: auto;
    }

    .FixedHeight {
        padding: 40px 0;
        width: 175px;
        height: 165px;
    }

    .fa-7x {
        font-size: 6em;
    }

    .col-md-4.product-type {
        margin-bottom: 10px;
    }
}


/*Mobile Responsive: was 500 but any screen res lower does not appear ok*/
@media only screen and (max-width: 768px) {
    .FensaBody {
        padding: 20px 20px;
    }

    .sign-in-container {
        padding-top: 2px !important;
        padding-bottom: 4px !important;
    }

    h2 {
        font-size: 1.4rem !important;
    }


    .FixedHeight {
        padding: 25px 0;
        height: 115px;
        width: 115px;
    }

    .fa-7x {
        font-size: 4em;
    }

    .headerClass img {
        display: none;
    }

    .notification {
        margin-right: 20px;
    }

    .sideBarMenuContainer {
        height: auto;
    }

    .form-control {
        margin-bottom: 12px;
    }

    a.btn.orange {
        margin-bottom: 10px;
    }

    .FensaBody h1 {
        text-align: center;
    }

    .navbar {
        margin-bottom: 0 !important;
    }

    .verify-btn {
        margin-top: 0;
    }

    .Banner {
        height: auto;
    }

    .headerClass {
        height: auto;
    }

    .Banner img {
        display: block;
        margin: auto;
    }

    .MenuFixedHeight {
        height: auto;
    }

    .SingleDatePicker {
        margin-bottom: 10px
    }

    .product-type img {
        width: 100%;
    }

    .numberwindows {
        margin-top: 20px;
        margin-left: 40px;
    }
}




.modal {
    font-size: 12px;
}

    .modal > .header {
        width: 100%;
        border-bottom: 1px solid gray;
        font-size: 18px;
        text-align: center;
        padding: 5px;
    }

    .modal > .content {
        width: 100%;
        padding: 10px 5px;
    }

    .modal > .actions {
        width: 100%;
        padding: 10px 5px;
        margin: auto;
        text-align: center;
    }

    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #ffffff;
        border-radius: 18px;
        border: 1px solid #cfcece;
    }


/*SLIDER*/

.slider {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    height: 10px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    margin-top: 10px;
}

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: #6A214F; /* Green background */
        cursor: pointer; /* Cursor on hover */
        border-radius: 50%;
    }

    .slider::-moz-range-thumb {
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: #6A214F; /* Green background */
        cursor: pointer; /* Cursor on hover */
        border-radius: 50%;
    }

ul.numbers {
    list-style: none;
    padding-left: 0;
}

.numbers li {
    float: left;
}

.numberwindows {
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    padding-left: 10px;
    height: 30px;
    margin-right: 10px;
}

/*CHES CSS*/
.card-body {
    margin-bottom: 20px;
}

.valid-completion-date {
    color: red;
    font-size: .775rem;
    margin-top: .7rem;
}

.DateInput {
    background: transparent !important;
}

.DateInput_input {
    background-color: transparent !important;
}

.SingleDatePickerInput__withBorder {
    margin-top: 23px;
}

.SingleDatePickerInput__withBorder {
    border: 0 none !important;
    border-bottom: 1px solid #dbdbdb !important;
}

.SingleDatePickerInput .DateInput .DateInput_input {
    font-size: 1rem !important;
    color: #757575;
    font-weight: 300;
}

.DateInput_input__focused {
    border-bottom: 2px solid #4189C7;
}


.card-item-wrapper {
    width: 350px;
}

.LogoCircle {
    margin-top: -26px;
}

.LogoCircle img {
    width: 80% !important;
}

.Benefits {
    padding: 18px 0;
}

    .Benefits img {
        width: 80% !important;
    }

iframe {
    width: 100%;
}


.Layout-sidebarImg-7 {
    max-height: 50px !important;
}

.add-btn {
    height: 50px;
}

.product {
    width: auto;
    margin-bottom: 10px;
}

.energy-label {
    width: 70%;
}

.benefits img {
    width: 50%;
}

.product-column img {
    width: 100%;
}

.rating-btn {
    width: 240px;
}

.lead {
    font-size: 1rem;
}

.jumbotron {
    padding: 1rem 0 !important;
    margin-top: 20px;
}

.DateInput_input {
    font-weight: 400;
}

.call-out {
    border-left: 0 !important;
}

.form-control {
    font-size: 0.9rem;
}

.rt-resizable-header-content {
    font-size: 0.9rem;
}

.rt-td {
    font-size: 1rem;
}

    .rt-td span {
        font-size: 1rem;
    }

.md-form label.active {
    font-weight: 500;
}

input.form-control::placeholder {
    color: #000;
}



/*.product-type button {
    width: 50%;
    margin: auto;
    padding: 25px;
    height: 160px;
    display: block;
}*/

.product-type button {
    width: 34%;
    margin: auto;
    height: 110px;
    display: block;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    padding: 0 10px;
}


label {
    color: #6A214F;
}

.card .FixedHeight {
    margin: auto;
    width: 100px;
    height: 100px;
    padding: 20px 0;
}

.FixedHeight img {
    width: 40%;
}

.FixedHeightImage {
    width: 60%;
}

.local-address {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.15);
    -webkit-box-shadow: 0 0 5px 1px #c9c9c9;
    box-shadow: 0 0 5px 1px #c9c9c9;
    margin-bottom: 20px
}

    .local-address h5 {
        color: #69214f
    }

    .local-address a {
        color: #69214f;
        padding-top: 10px;
        text-transform: uppercase
    }

.details a {
    font-size: 16px;
    color: #69214f
}

.details i {
    margin-right: 10px
}

.local-address i {
    color: #69214f;
    margin-left: 10px
}

.local-address .registered {
    background-color: #69214f
}

    .local-address .registered p {
        color: #fff;
        text-align: center;
        padding-top: 10px
    }

        .local-address .registered p span {
            font-weight: 700
        }

@media only screen and (max-width: 1200px) {
    .product-type button {
        width: 40% !important;
        height: 100px;
    }

    .product-type img {
        width: 50%;
    }

    .btn.btn-sm {
        font-size: 0.8rem;
    }

    .rating-btn {
        width: 190px;
    }

    .product {
        /*max-width: 33%;*/
    }

    .card-item-wrapper {
        width: 320px;
    }
}

@media only screen and (max-width: 1024px) {
    .product {
        width: 270px;
    }

    .card-item-wrapper {
        width: 300px;
    }
}

@media only screen and (max-width: 900px) {
    .card-wrapper {
        width: 50%;
    }
}


@media only screen and (max-width: 800px) {
    .pb-5, .py-5 {
        padding-bottom: 0 !important;
    }



    .jumbotron {
        padding: 1rem 1rem;
    }

        .jumbotron .px-5 {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }

    .lead {
        font-size: 1rem;
    }

    .product-type button {
        width: 60% !important;
        height: 100px !important;
    }

    .product-type img {
        width: 50% !important;
    }

    /*.md-form label.active {
            font-size: 0.7rem;
        }*/

    .md-form {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}


@media only screen and (max-width: 768px) {
    .product {
        width: 185px;
    }

    .btn-toolbar .btn {
        padding: 10px 20px;
    }

    .MuiTypography-h6-95 {
        font-size: 0.9rem !important;
    }

    .card-item-wrapper {
        width: 330px;
    }
}


/*iPHONE 8+*/
@media only screen and (max-width: 450px) {
    .Layout-content-16 {
        padding: 16px !important;
    }

    .lead {
        font-size: 1rem;
    }

    /*.lead {
            font-size: 0.8rem;
        }*/

    .btn.orange {
        padding: 0.84rem 0.9rem;
    }

    .pl-5, .px-5 {
        padding-left: 1rem !important;
    }

    .pb-5, .py-5 {
        padding-bottom: 4rem !important;
    }

    .pr-5, .px-5 {
        padding-right: 1rem !important;
    }

    .fa-7x {
        font-size: 3em;
        padding-top: 10px;
    }

    .FixedHeight {
        width: 115px;
        height: 115px;
        padding: 25px 0;
    }

        .FixedHeight img {
            width: 40px;
        }

    .form-group-fluid-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .btn-group {
        display: inline-grid;
        width: 100%;
    }

    .product-type button {
        width: 46% !important;
        margin: auto;
        padding: 15px;
        height: 90px !important;
        display: block;
    }

    .product-type img {
        width: 70% !important;
    }

    .invalid-feedback {
        display: none !important;
    }

    .card-wrapper {
        width: 100% !important;
    }

    /*.card-item-wrapper {
            min-width: 100% !important;
        }*/

    .Benefits img {
        width: 60%;
        height: auto;
    }

    .energy-label {
        width: 70%;
    }

    .product-type .product-small {
        width: 55px !important;
        margin-right: auto;
        margin-left: auto;
        display: block;
        float: none;
    }

    .non-fensa {
        text-align: center;
    }

    .slider#NoneFensaWindows {
        margin-top: 0 !important;
        /*margin-left: 60px;*/
    }

    .slider#NoneFensaDoors {
        margin-top: 0 !important;
        /*margin-left: 60px;*/
    }

    .product {
        max-width: 100%;
    }

    .slider {
        width: 100%;
    }

    .MuiSlider-thumb {
        background-color: green !important;
        color: #6A214F !important;
    }

    .Layout-menuButton-10 {
        margin-right: 0 !important;
    }

    .MuiTypography-h6-95 {
        font-size: 0.8rem !important;
    }

    input#NonFensaWindows {
        margin-left: 0 !important;
    }

    input#NonFensaDoors {
        margin-left: 0 !important;
    }

    .verify-button {
        padding: 0.5rem 0.5rem !important;
    }

    .Benefits {
        padding: 12px 0;
    }

    .card-item-wrapper {
        width: 100%;
    }

    .add-btn {
        width: 70%;
        margin: 0;
        padding: 0 !important;
    }

    .md-form label {
        font-size: 0.8rem;
    }
}

input#NonFensaWindows {
    margin-left: 25px;
}

input#NonFensaDoors {
    margin-left: 25px;
}


.slider#NoneFensaWindows {
    margin-top: 40px;
}

.slider#NoneFensaDoors {
    margin-top: 40px;
}

/*iPHONE 8*/
@media only screen and (max-width: 400px) {
    a.card-link {
        font-size: 0.8em;
    }

    .product {
        width: 100%;
    }

    .form-group-fluid-container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .product-type button {
        height: 95px !important;
    }

    .h3, h3 {
        font-size: 1rem;
    }

    .pl-5 {
        padding-left: 0 !important;
    }

    .header img {
        width: 100px;
    }

    button.jss10 {
        margin-right: 10px;
    }

    header h6.jss78 {
        font-size: 0.8em;
    }

    header h1.jss78 {
        font-size: 0.8rem;
    }
}




/*INTERNET EXPLORER 10+*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

    .slider {
        display: none;
    }

    .product {
        width: 333px;
    }

    .col-xs-12 {
        width: 100%;
    }
}



.box {
    width: 100%;
    margin: 0 auto;
    background: #6A214F;
    padding: 15px;
    background-clip: padding-box;
    border-radius: 5px;
}

    .box p {
        color: #fff;
    }

    .box .button {
        background-color: #fff;
        font-size: 1em;
        padding: 10px;
        color: #6A214F;
        border-radius: 20px;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease-out;
    }

        .box .button:hover {
            background: #ff6a00;
            color: #fff;
        }

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}

    .overlay:target {
        visibility: visible;
        opacity: 1;
        z-index: 10000;
    }

.popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 70%;
    position: relative;
    transition: all 5s ease-in-out;
}

    .popup h2 {
        margin-top: 0;
        color: #333;
    }

    .popup .close {
        position: absolute;
        top: 20px;
        right: 30px;
        transition: all 200ms;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        color: #333;
    }

        .popup .close:hover {
            color: #6A214F;
        }

    .popup .content {
        max-height: 30%;
        overflow: auto;
    }

@media screen and (max-width: 700px) {
    .box {
        width: 100%;
    }

    .popup {
        width: 90%;
    }
}

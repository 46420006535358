.popup {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 1rem;
    background-color: rgba(0,0,0, 0.5);
    z-index:1;
    
    
  }
  
  .popup_inner {
    position: fixed;
    left: 35%;
    right: 25%;
    top: 50%;
    bottom: auto;
    margin: auto;
    background: white;
    padding: 1rem;
    cursor: pointer;
    min-height: 10vh;
    overflow-y: auto;
  }
  
  
  .btn {
    margin: 0 0 0 150px;
    
  }
  
  .btnweb {
  margin: 0 0 0 200px;
  text-transform:uppercase;
  border:0 ;
  padding: 0.84rem 2.14rem;
  font-size: 0.81rem;
  }
  
  
  .circle {
  border-radius: 64px;
  background: #85286c;
  color: white;
  font-weight: bold;
  }
  
  
  .buttons {
  
  margin: 0 auto;
  display: inline;}
     
  .action_btn {
  
  margin: 0px 0 0 60px; 
  display: inline;
  position: absolute;}
  
  .close{
    margin-right:-28px;
    margin-top:-20px;
  }
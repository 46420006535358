.CmpnyVideo {
    position: absolute; /* Positioning and size */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128,128,128,0.5); /* color */
    z-index:1;
    cursor: pointer;
  }
  
  
  .iframe-container {
      position: absolute;
     width:50%;
     height:50%;
      top: 40%;
      left:20%;
      right: 20%;
      bottom: 20%; 
      margin: auto;
      padding: 1rem;
      background-color: rgba(0,0,0, 0.5);
      z-index:1;
      
    }